import React, { useCallback } from 'react';
import { Button, Flex, Image, Icon, Label } from '@zeal/web-ui';
import background from './background.svg';
import { useNavigate } from 'react-router-dom';
import { BannerIconContainer } from '../StyledComponents';
import { useLoggedIn } from '@app/ACL/useLogin';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '@app/AppRoutes/routes';

export default function AppMenuBanner() {
	const navigate = useNavigate();

	const { t } = useTranslation('freemiumDashboard');

	const { merchantType } = useLoggedIn();

	const handleFeatureClick = useCallback(() => {
		navigate({
			pathname: ROUTES.FREEMIUM_DASHBOARD.UPGRADE_PLAN.path,
		});
	}, [navigate]);

	if (merchantType !== 'freemium') {
		return null;
	}

	return (
		<div className="relative text-white">
			<div
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					backgroundImage: `url(${background})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					borderRadius: '8px',
				}}
			/>
			<Flex gap="3" align="center" isColumn className="w-full relative p-4">
				<BannerIconContainer align="center" justify="center">
					<Icon
						iconName="crown"
						variant="regular"
						color="#F1C40F"
						style={{
							width: '24px',
							height: '24px',
						}}
					/>
				</BannerIconContainer>
				<Flex isColumn className="gap-[6px] w-56">
					<Label.Big300 textAlign="center" variant="on-primary">
						{t('upgrade_to_business')}
					</Label.Big300>
					<Label.Thin200 textAlign="center" variant="on-primary">
						{t('unlock_more_features')}
					</Label.Thin200>
				</Flex>
				<Button
					size="md"
					variant="white"
					className="w-full"
					onClick={handleFeatureClick}
				>
					✨ {t('upgrade_now')}
				</Button>
			</Flex>
		</div>
	);
}
