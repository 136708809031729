import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate, Outlet } from 'react-router-dom';

export const PremiumDashboardPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.PREMIUM_DASHBOARD.path,
			element: <Outlet />,
			children: [
				{
					index: true,
					element: <Navigate to={ROUTES.PREMIUM_DASHBOARD.DASHBOARD.path} />,
				},
				{
					path: ROUTES.PREMIUM_DASHBOARD.$.DASHBOARD.relativePath,
					element: lazy(() => import('./PremiumDashboard')),
				},
			],
		},
	],
};
