import { create } from 'zustand';
import { ModalProps } from '@zeal/web-ui';

type OpenModalParams = Omit<
	ModalProps,
	'children' | 'onCancel' | 'isLoading' | 'isError' | 'isOpen'
> &
	Partial<Pick<ModalProps, 'children' | 'onCancel' | 'isLoading' | 'isError'>>;

type ModalStore = ModalProps & {
	openModal: (params: OpenModalParams) => void;
};

export const useModalStore = create<ModalStore>()((set) => ({
	isOpen: false,
	title: '',
	onConfirm: () => {},
	isDelete: false,
	children: '',
	isLoading: false,
	isError: false,
	confirmTitle: '',

	onCancel: () => {
		set({
			isOpen: false,
			isError: false,
			isLoading: false,
			children: '',
			confirmTitle: '',
			title: '',
			isDelete: false,
			onConfirm: () => {},
		});
	},

	openModal: (params: OpenModalParams) => {
		const { onConfirm, ...rest } = params;

		set({
			...rest,
			isOpen: true,
			onConfirm: async () => {
				try {
					set({ isLoading: true });
					await onConfirm();
					set({ isOpen: false, isError: false });
				} catch (error) {
					set({ isError: true });
				} finally {
					set({ isLoading: false });
				}
			},
			onCancel: () => {
				set({ isOpen: false, isError: false });
			},
		});
	},
}));

export const useOpenModal = () => {
	return useModalStore((state) => state.openModal);
};
