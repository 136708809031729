import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate, Outlet } from 'react-router-dom';

export const FreemiumDashboardPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.FREEMIUM_DASHBOARD.path,
			element: <Outlet />,
			children: [
				{
					index: true,
					element: <Navigate to={ROUTES.FREEMIUM_DASHBOARD.DASHBOARD.path} />,
				},
				{
					path: ROUTES.FREEMIUM_DASHBOARD.$.DASHBOARD.relativePath,
					element: lazy(() => import('./FreemiumDashboard')),
				},
				{
					path: ROUTES.FREEMIUM_DASHBOARD.$.UPGRADE_PLAN.relativePath,
					element: <Outlet />,
					children: [
						{
							index: true,
							element: lazy(() => import('./UpgradePlan')),
						},
						{
							path: ROUTES.FREEMIUM_DASHBOARD.UPGRADE_PLAN.$.CONFIRMATION
								.relativePath,
							element: lazy(
								() => import('./components/upgradePlan/Confirmation')
							),
						},
					],
				},
			],
		},
	],
};
