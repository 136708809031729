import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions, RouteFlags } from '../App/types/types';

const wheelOfFortunePathOptions: TPathOptions = {
	apiKey: 'wheel_of_fortune',
	path: ROUTES.WHEEL_OF_FORTUNE.path,
	label: 'wheeloffortune',
	iconName: 'tireFlat',
	menuOrder: 7,
	ownApp: true,
  excludedFor:[],
	subMenu: [
		{
			path: ROUTES.WHEEL_OF_FORTUNE.path,
			label: 'wheels',
		},
		{
			path: ROUTES.WHEEL_OF_FORTUNE.$.REDEMPTION_DETAILS.path,
			label: 'archive',
		},
		{
			path: ROUTES.WHEEL_OF_FORTUNE.$.REPORT.path,
			label: 'reports',
		}
	]
};

export default wheelOfFortunePathOptions;
